<script>

import appConfig from "@/app.config.json";
import helper from "@/assets/helpers/helper";
import PageHeader from "@/components/structure/page-header";
import Edit from "@/components/popups/edit";


export default {
  page: {
    title: "Saved Posts",
    meta: [{name: "description", content: appConfig.description}]
  },
  name: 'SavedPosts',
  components: {Edit, PageHeader},
  data() {
    return {
      title: "SavedPosts",
      user_posts: 1,
      companie_posts: 1,
      posts_users: [],
      posts_companies: [],
      selectedPost: '',
      interactions: [
        {
          id: 1,
          title: 'like',
          icon: 'bx bxs-like text-primary',

        },
        {
          id: 2,
          title: 'happy',
          icon: 'bx bxs-happy-alt text-warning',

        },
        {
          id: 3,
          title: 'love',
          icon: 'bx bxs-heart text-danger',

        },
        {
          id: 4,
          title: 'shocked',
          icon: 'bx bxs-shocked text-warning',

        },
        {
          id: 5,
          title: 'sad',
          icon: 'bx bxs-sad text-warning',

        },

      ],
      post_id: '',
      company_id: '',
      selectedType: '',
      sharing: {
        url: 'https://crisi5.com',
        title: 'Sharing Post',
        description: 'The post desc',
        quote: 'the post content',
        hashtags: 'CRISI5',
        twitterUser: 'youyuxi'
      },
      networks: [
        {network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2'},
        {network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5'},
        {network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2'},
      ]

    }
  },

  methods: {

    infiniteHandlerUserPosts($state) {
      console.log($state);
      this.$http.get('me/saved_posts/users?page=' + this.user_posts).then(({data}) => {
        if (data.data.length > 0) {
          this.user_posts += 1;
          this.posts_users.push(...data.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    // infiniteHandlerCompaniePosts($state) {
    //   console.log($state);
    //   this.$http.get('me/saved_posts/companies?page=' + this.companie_posts).then(({data}) => {
    //     if (data.data.length) {
    //       this.companie_posts += 1;
    //       this.posts_companies.push(...data.data);
    //       $state.loaded();
    //     } else {
    //       $state.complete();
    //     }
    //   });
   // },
    async hidePost(id, type) {
      let posts = []
      if (type == 'companie') {
        await helper.postData('companies/posts/' + id + '/unsave')
        posts = this.posts_companies.filter(function (ele) {
          return ele.post_id != id;
        });
        this.posts_companies = posts
      } else {
        await helper.postData('posts/' + id + '/unsave')
        posts = this.posts_users.filter(function (ele) {
          return ele.post_id != id;
        });
        this.posts_users = posts
      }


    },

    async intract(i, post, company_id) {
      let a = {}
      if (company_id) a = await helper.postReturnData('companies/' + company_id + '/posts/' + post.post_id + '/interactions', {'relation': i})
      else a = await helper.postReturnData('posts/' + post.post_id + '/interactions', {'relation': i})
      if (!a) {
        post.interactions_count = post.interactions_count - 1
        post.my_interaction = [];
      } else {
        if (!post.my_interaction[0]) post.interactions_count = post.interactions_count + 1
        this.$set(post.my_interaction, 0, a);

      }

    },
    refresh(content) {
      if (this.selectedType == 'posts' || this.selectedType == 'companies') this.selectedPost.content = content
    },

  }
};
</script>

<template>
  <div>

    <div class="row">
      <div class="col-xl-6">
        <PageHeader :title="$t('navbar.dropdown.henry.list.saved_posts')"/>

        <!-- Posts  -->
        <div class="card" v-for="post of posts_users" :key="post.post_id">
          <div class="card-body" >

            <div class="media" >
              <b-avatar :src="post.user.avatar" v-if="post.user.avatar" class="mr-4 "></b-avatar>

              <b-avatar v-else variant="primary" :text="(post.user.username).substr(0, 1)"
                        class="align-baseline mr-4"></b-avatar>

              <div class="media-body overflow-hidden">
                <h5>
                  <router-link class="h5 text-truncate font-size-15 link"
                               :to="{name:'ProfilUser',params:{id:post.user_id}}">
                    {{ post.user.username }}
                  </router-link>
                </h5>
                <router-link class="link text-muted" v-if="post.post"
                             :to="{name:'postPage',params:{id:post.post_id}}">
                  {{ post.post.time_diff }}.
                </router-link>
              </div>
              <b-dropdown class="float-right ml-2" variant="white" right toggle-class="text-muted">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-horizontal font-size-18"></i>
                </template>
                <b-dropdown-item @click="hidePost(post.post_id)">{{ $t('post.hide') }}</b-dropdown-item>

              </b-dropdown>

            </div>

            <p class="text-muted mt-2" v-if="post.post">{{ post.post.content }}</p>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div class="like-block position-relative d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <div class="like-data">
                                         <span class="dropdown-toggle" :id="'tooltip'+post.post_id" aria-haspopup="true"
                                               aria-expanded="true" role="button">
                                                <i class="interactions"
                                                   :class="post.my_interaction.length!=0 ?interactions[post.my_interaction[0].relation-1].icon:'bx bxs-like'"></i>
                                         </span>
                    <b-tooltip :target="'tooltip'+post.post_id" placement="bottom" triggers="hover">
                      <i class="interactions" v-for="inter of interactions" :key="inter.id"
                         :class="inter.icon" :title="inter.title" @click="intract(inter.id,post)"></i>
                    </b-tooltip>
                  </div>

                  <div class="total-like-block ml-2 mr-3">{{ post.interactions_count }}</div>
                </div>
                <div class="total-comment-block">{{ post.comments_count }} {{ $t('post.comments') }}</div>
              </div>
              <div class="share-block d-flex align-items-center feather-icon mr-3">
                <b-dropdown class="float-right ml-2" variant="white" right toggle-class="text-muted">
                  <template v-slot:button-content>
                    <i class="fas fa-share mr-2"></i> <span class="link">{{ $t('post.share') }}</span>
                  </template>


                  <ShareNetwork class="dropdown-item"
                                v-for="network in networks"
                                :network="network.network"
                                :key="network.network"
                                :style="{color: network.color}"
                                :url="sharing.url"
                                :title="sharing.title"
                                :description="sharing.description"
                                :quote="sharing.quote"
                                :hashtags="sharing.hashtags"
                                :twitterUser="sharing.twitterUser"
                  >
                    <i :class="network.icon"></i>
                    <span class="ml-3">{{ network.name }}</span>
                  </ShareNetwork>

                </b-dropdown>
              </div>
            </div>

          </div>

        </div>
        <infinite-loading @infinite="infiniteHandlerUserPosts">
          <div slot="no-more">{{ $t('validation.no_more') }}</div>
          <div slot="no-results">{{ $t('validation.no_saved') }}</div>
        </infinite-loading>


      </div>

<!--      <div class="col-xl-6 ">-->
<!--        <PageHeader :title="$t('navbar.dropdown.henry.list.saved_posts_companies')"/>-->

<!--        &lt;!&ndash; Posts of Companies &ndash;&gt;-->
<!--        <div class="card" v-for="post of posts_companies" :key="post.post_id">-->
<!--          <div class="card-body" v-if="posts_companies.length">-->
<!--            <div class="media" v-if="post.post.company">-->
<!--              <b-avatar :src="post.post.company.logo" v-if="post.post.company.logo" class="mr-4 "></b-avatar>-->

<!--              <b-avatar v-else variant="primary" :text="(post.post.company.name).substr(0, 1)"-->
<!--                        class="align-baseline mr-4"></b-avatar>-->

<!--              <div class="media-body overflow-hidden">-->
<!--                <h5>-->
<!--                  <router-link class="h5 text-truncate font-size-15 link"-->
<!--                               :to="{name:'ProfilCompany',params:{id:post.post.company.id}}">-->
<!--                    {{ post.post.company.name }}-->
<!--                  </router-link>-->
<!--                </h5>-->
<!--                <router-link class="text-muted link"-->
<!--                             :to="{name:'postCompany',params:{company_id:post.post.company.id,id:post.post_id}, query: { company_id: post.post.company.company_id }}">-->
<!--                  {{ post.post.time_diff }}.-->
<!--                </router-link>-->
<!--              </div>-->
<!--              <b-dropdown class="float-right ml-2" variant="white" right toggle-class="text-muted">-->
<!--                <template v-slot:button-content>-->
<!--                  <i class="mdi mdi-dots-horizontal font-size-18"></i>-->
<!--                </template>-->
<!--                <b-dropdown-item @click="hidePost(post.post_id,'companie')">{{ $t('post.hide') }}</b-dropdown-item>-->
<!--              </b-dropdown>-->

<!--            </div>-->

<!--            <p class="text-muted mt-2">{{ post.post.content }}</p>-->
<!--            <hr>-->
<!--            <div class="d-flex justify-content-between align-items-center">-->
<!--              <div class="like-block position-relative d-flex align-items-center">-->
<!--                <div class="d-flex align-items-center">-->
<!--                  <div class="like-data">-->
<!--                   <span class="dropdown-toggle" :id="'tooltip1'+post.post_id" aria-haspopup="true"-->
<!--                                               aria-expanded="true" role="button">-->
<!--                   <i class="interactions"-->
<!--                      :class="post.my_interaction.length!=0 ?`${interactions[post.my_interaction[0].relation-1].icon}`:'bx bxs-like'"></i>-->
<!--                    </span>-->
<!--                    <b-tooltip :target="'tooltip1'+post.post_id" placement="bottom" triggers="hover">-->
<!--                      <i class="interactions" v-for="inter of interactions" :key="inter.id"-->
<!--                         :class="inter.icon" :title="inter.title"-->
<!--                         @click="intract(inter.id,post,post.post.company_id)"></i>-->
<!--                    </b-tooltip>-->
<!--                  </div>-->
<!--                  <div class="total-like-block ml-2 mr-3">{{ post.interactions_count }}</div>-->
<!--                </div>-->
<!--                <div class="total-comment-block">{{ post.comments_count }} {{ $t('post.comments') }}</div>-->
<!--              </div>-->
<!--              <div class="share-block d-flex align-items-center feather-icon mr-3">-->
<!--                <b-dropdown class="float-right ml-2" variant="white" right toggle-class="text-muted">-->
<!--                  <template v-slot:button-content>-->
<!--                    <i class="fas fa-share mr-2"></i> <span class="link">{{ $t('post.share') }}</span>-->
<!--                  </template>-->


<!--                  <ShareNetwork class="dropdown-item"-->
<!--                                v-for="network in networks"-->
<!--                                :network="network.network"-->
<!--                                :key="network.network"-->
<!--                                :style="{color: network.color}"-->
<!--                                :url="sharing.url"-->
<!--                                :title="sharing.title"-->
<!--                                :description="sharing.description"-->
<!--                                :quote="sharing.quote"-->
<!--                                :hashtags="sharing.hashtags"-->
<!--                                :twitterUser="sharing.twitterUser"-->
<!--                  >-->
<!--                    <i :class="network.icon"></i>-->
<!--                    <span class="ml-3">{{ network.name }}</span>-->
<!--                  </ShareNetwork>-->

<!--                </b-dropdown>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--        <infinite-loading @infinite="infiniteHandlerCompaniePosts">-->
<!--          <div slot="no-more">{{ $t('validation.no_more') }}</div>-->
<!--          <div slot="no-results">{{ $t('validation.no_saved') }}</div>-->
<!--        </infinite-loading>-->
<!--      </div>-->

    </div>
    <edit :content="selectedPost" :post_id="post_id" :type="selectedType" :company_id="company_id"
          v-on:refresh="refresh"/>
    <!-- end row -->
  </div>
</template>
<style scoped>
i.interactions {
  font-size: 24px !important;
  margin-right: 6px !important;
  cursor: pointer !important;
}

</style>
